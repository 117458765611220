// Font sizes
// -----------------------------------
$font-size-body: 14px;

// Font Declarations
// -----------------------------------
$font-base: sans-serif;

$font-primary: 'Gotham A', 'Gotham B', $font-base;
$font-weight-book: 400;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Headings.
// -----------------------------------
h1, h2, h3, h4, h5, h6 {
	margin: 0 0 1em 0;
	font-weight:normal;
	line-height:1.4;

	&.lined {
		position: relative;
		padding-bottom:30px;
		&:after {
			width:25px;
			height:1px;
			content:'';
			display:block;
			position:absolute;
			bottom:0px;
		}
	}

	&.center {
		text-align:center;
		&:after {
			left:50%;
			transform:translateX(-50%);
		}
	}
}

h1 {
	@include font-size(50px);
	@include media($laptopHeightOnly){
		@include font-size(40px);
	}

	&.big {
		@include font-size(70px);
		line-height:1;
	}
}

h2 {
	@include font-size(28px);
	line-height:1.4;
}

h3 {
	@include font-size(22px);
	line-height:1.4;
}

// p, ul, ol {
// 	@include font-size(18px);
// 	line-height:1.55;
// }


p.intro {
	@include font-size(45px);
	line-height:1.4;
}

.double-lined{
	line-height:2.2;
}