// Variables //
// -----------------------------------

/* Global variables */

$container-padding: 108px;
$interiorbox-padding: 30px;
$mainNavWidth: 343px;
$tabNavHeight: 78px;
$mobileNavHeight: 65px;
$mobileCalHeight: 700px;

/* Page Color Variables -- Make it easier to change a page's and its corresponding elements' theme colors */

$pagecolor1: $brand-primary2; // teal
$pagecolor2: $brand-secondary1; // orange
$pagecolor3: $brand-secondary3; // magenta
$pagecolor4: $brand-secondary2; // purple

// Animations //
// -----------------------------------

/* Bounce */

@keyframes bounce {
	0% {
		transform: translateY(5px);
	}
	100% {
		transform: translateY(-3px);
	}
}

/* Nav-burger animation */

@keyframes hamburgerTop {
	0% {
		transform: none;
	}
	20% {
		transform: translateY(16px);
	}
	50% {
		transform: translateY(16px);
	}
	100% {
		transform: translateY(8px) rotate(45deg);
	}
}
@keyframes hamburgerMiddle {
	0% {
		transform: none;
	}
	20% {
		transform: translateY(8px);
	}
	50% {
		transform: translateY(8px);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes hamburgerBottom {
	0% {
		transform: none;
	}
	50% {
		transform: none;
	}
	100% {
		transform: translateY(-8px) rotate(-45deg);
	}
}

/* Magnifying glass animation */

@keyframes squishy {
	0% {
		transform: none;
	}
	50% {
		transform: scale(1, 0.15);
		background: $brand-primary1;
	}
	100% {
		transform: translateY(10px) scale(1,0.15);
		background: $brand-primary1;
	}
}

@keyframes squishyXLeft {
	0% {
		transform: none;
	}
	52% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(-10px) rotate(45deg);
	}
}

@keyframes squishyXRight {
	0% {
		transform: none;
	}
	52% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(-10px) rotate(-45deg);
	}
}

/* Sticky Nav Animation */

@keyframes stickyNavDesktop {
	0% {
		top: -100%;
	}
	100% {
		top: 0;
	}
}
@keyframes stickyNavTablet {
	0% {
		top: 0;
	}
	100% {
		top: $tabNavHeight;
	}
}
@keyframes stickyNavMobile {
	0% {
		top: 0;
	}
	100% {
		top: $mobileNavHeight;
	}
}

/* Articles Next & Prev animation */

@keyframes articleNavText {
	0% {
		width: 0%;
		opacity: 0;
	}
	15% {
		opacity: 0;
	}
	100% {
		width: auto;
		opacity: 1;
	}
}

@keyframes barFromLeft {
	0% { left: -100%; }
	100% { left: 0; }
}

@keyframes barFromRight {
	0% { right: -100%; }
	100% { right: 0; }
}

// Global Styles //
// -----------------------------------

/**
 * Box sizing
 */
html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html,
body {
  width:100%;
  height:100%;
}

body {
	padding-left: $mainNavWidth;
	background: #FFF;
	font-family: $font-primary;
	font-size: $font-size-body;
	font-weight: $font-weight-book;
	line-height: 1.375;
	color: $body-text;
	@include media($smDeskAndLess) {
		padding-left: $mainNavWidth*0.75;
	}
	@include media($maxWidthAndHeight) {
		padding-left: 0;
		padding-top: $tabNavHeight;
	}
	@include media($mobileOnly) {
		padding-top: $mobileNavHeight;
	}
	&.front {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		&.flex {
			display: flex;
			@include media($maxWidthAndHeight) {
				display: block;
			}
		}
		main {
			flex-grow: 1;
			width: 100%;
			display: flex;
			flex-direction: column;
			@include media($maxWidthAndHeight) {
				display: block;
				//height: 100%;
			}
		}
		@include media($maxWidthAndHeight) {
			display: block;
		}
	}
	&.page-about {
		main {
			padding: $container-padding/2 0 $container-padding 0;
		}
	}
}

a {
	color: $brand-secondary2;
	text-decoration: none;
	transition: all 0.2s ease;
	&:hover, &:active, &:focus {
		color: $brand-secondary3;
		outline: 0;
	}
}

img {
	max-width: 100%;
}

.hidden, .element-invisible {
  display: none;
}

.text-center {
	text-align: center;
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
}
#body-overlay {
	z-index: 1;
	background: rgba($brand-primary1,0.8);
}
#magazine-overlay {
	display: none;
	background: rgba($brand-secondary2,0.9);
	z-index: 11;
}

.container {
	@include outer-container;
	padding: 0 $container-padding;
	@include media($ptabAndLess) {
		padding: 0 ($container-padding/2);
	}
	@include media($mobileOnly) {
		padding: 0 ($container-padding/3.5);
	}
}

h1 {
	font-weight: $font-weight-medium;
	font-size: 23px;
	line-height: 1.36;
	letter-spacing: 1px;
}

main {
	min-height: 26vh;
	&.generic-page {
		padding-bottom: 100px;
	}
	p, li {
		font-size: 18px;
		line-height: 1.55;
		font-weight: $font-weight-light;
		@include media($maxWidthAndHeight) {
			font-size: 16px;
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}
}

.more-info-hover {
	cursor: pointer;
	color: $brand-secondary3;
	&:after {
		background: $brand-secondary3;
		transform: translateX(100%);
	}
}

.more-info {
	display: inline-block;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: $font-weight-medium;
	color: $brand-secondary2;
	letter-spacing: 2px;
	overflow: hidden;
	&:hover, &:active {
		@extend .more-info-hover;
	}
	&:after {
		content: " ";
		display: block;
		background: $brand-secondary2;
		height: 1px;
		width: 100%;
		transition: color 0.3s ease, transform 0.4s ease;
	}
}

.scroll-hint {
	transition: opacity 0.3s ease;
	animation: bounce 1s ease infinite alternate;
}

.container {
	div.tabs {
		margin: 0 -35px 35px -35px;
		&:first-child {
			border: 1px solid $brand-secondary2;
		}
		ul.tabs {
			padding: 35px 35px 30px 35px;
		}
	}
}
body.front div.tabs {
  position: absolute;
  z-index: 1000;
}
ul.tabs {
	margin: 0;
	padding: 35px;
	display: flex;
	justify-content: flex-start;
	@include media ($ptabAndLess) {
		flex-wrap: wrap;
	}
	li {
		list-style: none;
		margin-right: 35px;
		@include media ($ptabAndLess) {
			width: 100%;
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&:last-child {
			margin-right: 0;
		}
		a {
			@extend .more-info;
		}
	}
}

main div.messages {
	margin: 35px 0;
	padding: 0;
	background: none;
	border: 0;
	a {
		color: inherit;
		border-bottom: 1px dotted;
		&:hover {
			color: $body-text;
		}
	}
	&:before {
		font-family: "FontAwesome";
	}
	&.error {
		color: #d62121;
		&:before {
			content: "\f06a";
		}
	}
	&.status {
		color: #46a900;
		&:before {
			content: "\f058";
		}
	}
}

// Global Styles -- Header //
// -----------------------------------

body.front .misc-nav {
	right: 50px;
}

.misc-nav {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	height: 30px;
	width: 36px;
	padding: 8px;
	position: fixed;
	top: 26px;
	body.navbar-administration & {
  	top: 65px;
	}
	body.navbar-administration.navbar-tray-open & {
  	top: 105px;
	}
	right: 10px;//30px;
	z-index: 99;
	@include media ($maxWidthAndHeight) {
		display: none;
	}
	span {
		display: block;
		height: 2px;
		background: #fff;
		opacity: 0.7;
		transition: all 0.15s linear;
		&.top {
			width: 17px;
		}
		&.middle {
			width: 20px;
		}
		&.bottom {
			width: 13px;
		}
	}
	&:before {
		position: absolute;
		top: 6px;
		right: 105%;
		content: "Menu";
		text-transform: uppercase;
		font-weight: $font-weight-medium;
		letter-spacing: 1px;
		color: #fff;
		transition: all 0.15s linear;
		@media(max-width:376px) {
			display: none;
		}
	}
	&.no-label {
		&:before {
			opacity: 0;
		}
	}
	&:hover, &:active, &.open {
		cursor: pointer;
		transition: all 0.3s ease;
		span {
			width: 100%;
			opacity: 1;
		}
	}
	body.node-type-article &, body.node-type-event & {
		span {
			background: $brand-secondary2;
		}
	}
	body:not(.front) & {
		&.dark {
			span {
				background: $brand-secondary2;
			}
			&:before {
				color: $brand-secondary2;
			}
		}
	}
	&.open {
		span {
			width: 17px;
			background: $brand-secondary2;
			&.top {
				transform: rotate(45deg);
			}
			&.middle {
				transform: scale(0);
			}
			&.bottom {
				transform: rotate(-45deg);
			}
		}
		&:before {
			color: $brand-secondary2;
		}
	}
}

header.page-header, .misc-menu {
	position: fixed;
	z-index: 15;
	width: $mainNavWidth;
	height: 100%;
	transition: all 0.25s ease;
	-webkit-overflow-scrolling: touch;
	overflow: visible;
}

.misc-menu {
	top: 0;
	right: -$mainNavWidth;
	background: $brand-grey1;
	padding: 100px 30px 50px 30px;
	transition: right 0.3s ease-out;
	&.open {
		right: 0;
	}
	@include media ($maxWidthAndHeight) {
		position: relative;
		right: auto;
		width: 100%;
		padding: 0;
		background: transparent;
	}
	a[href^="mailto"] {
		&:after {
			margin-left: 10px;
			content: "\f003";
			font-family: "FontAwesome";
		}
	}
}

header.page-header {
	body.editors-menu & {
		left: 220px;
		width: calc(100% - 220px);
	}
	top: 0;
	left: 0;
	padding: 47px 43px;
	background: $brand-grey1;
	overflow: visible;
	body.navbar-administration & {
  	top: 39px;
  }
  body.navbar-administration.navbar-tray-open & {
    top: 79px;
  }

	&.open {
		height: 100%;
	}
	a {
		display: block;
	}
	.logo {
		display: inline-block;
		img {
			display: block;
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
	nav {
		margin-top: $tabNavHeight;
		ul {
			margin: 0 0 20px 0;
			padding: 0;
			ul {
  			margin: 0;
  			//max-height: 0;
  			//overflow: hidden;
  			transition: opacity .25s;
  			//left: 2em;
  			top: 3.5em;
  			max-width: 150%;
  			top: 0;
  			left: 99%;
  			padding: 0;// 27px 1em;
  			@include media($maxWidthAndHeight) {
    			max-height: 0;
    			overflow: hidden;
    			transition: max-height .5s;

  			}
      	@include media($minWidthAndHeight) {
    			position: absolute;
    			left: -400%;
    			top: -1px;
        	background: $brand-grey1;
    			opacity: 0;
    			padding: 24px 0 24px 24px;
    			box-shadow: 0 1px 4px rgba(0,0,0,.3);
    			z-index: 2;
    			border-top: 1px solid $brand-grey2;
      	}
			}
  		@include media($maxWidthAndHeight) {
  			li.expanded ul.submenu {
    			max-height: 800px;
    		}
  		}
			li {
				list-style: none;
				position: relative;
				z-index: 1;
      	@include media($minWidthAndHeight) {
				&.first ul {
  				border-top: 1px solid $brand-grey1;
				}
				}
				a, span {
					display: block;
					padding: 27px 0;
					font-weight: $font-weight-medium;
					color: $brand-primary1;
					font-size: 16px;
					line-height: 1.5;
					//border-bottom: 1px solid $brand-grey2;
					white-space: nowrap;
					transition: all 0.2s ease, padding 0.15s linear;
          	@include media($minWidthAndHeight) {
            	background: $brand-grey1;
          	}

					i.fa {

  					color: #fff;
      	@include media($minWidthAndHeight) {
						color: $brand-secondary3;
						}
					}
					position: relative;
					z-index: 5;
					&.depth-2 {
      	@include media($minWidthAndHeight) {
          	background: $brand-grey1;
          	}
        	}
				}

      	@include media($minWidthAndHeight) {
  				&:hover {
    				z-index: 2;
    				ul {
      				left: 100%;
    					max-height: 500px;
    					opacity: 1;
  					}
  				}
				}
				/*
				&.below a.depth-1:after {
  				content:' >';
  				position: absolute;
  				top:1em;
  				right: 0;
				}*/


				// Submenu:
				li {
  				//& + li {
    				//border-top: none;
  				//}
  				a {
    				font-size: 14px;
            padding: 5px 29px;
    				border-bottom: none;
    				@include media($maxWidthAndHeight) {
      				white-space: normal;
              padding: 5px 0 5px 42px;
    				}
          	@include media($minWidthAndHeight) {
            	background: $brand-grey1;
          	}
  				}
  				@include media($maxWidthAndHeight) {
    				&.depth-2.last a {
      				padding-bottom: 10px;
    				}
  				}
				}
				&.depth-1 + li.depth-1 {
					border-top: 1px solid $brand-grey2;
				}
				&.depth-1.last {
  				border-bottom: 1px solid $brand-grey2;
				}
				&.depth-1:not(.active):hover {
					a.depth-1, span {
						//&:hover {
							padding-left: 12px;
						//}
					}
				}

				a.depth-2:hover {
						color: $brand-secondary3;
				}
				&.active, &:hover {
					// This is the default interaction if no colors are specified for the menu item
					cursor: pointer;
					a.depth-1, span {
						color: $brand-secondary3;
						border-color: $brand-secondary3;
					}
				}
				// &:first-child { // Page: Initiating Change
				// 	&.active, &:hover {
				// 		a {
				// 			color: $pagecolor1;
				// 			border-bottom: 1px solid $pagecolor1;
				// 		}
				// 	}
				// }
				// &:nth-child(2) { // Page : Strengthening Communities
				// 	&.active, &:hover {
				// 		a {
				// 			color: $pagecolor2;
				// 			border-bottom: 1px solid $pagecolor2;
				// 		}
				// 	}
				// }
				// &:nth-child(3) { // Page : Guidelines and Clinical Support
				// 	&.active, &:hover {
				// 		a {
				// 			color: $pagecolor3;
				// 			border-bottom: 1px solid $pagecolor3;
				// 		}
				// 	}
				// }
				// &:last-child { // Page: Collaborative Practice
				// 	&.active, &:hover {
				// 		a {
				// 			color: $pagecolor4;
				// 			border-bottom: 1px solid $pagecolor4;
				// 		}
				// 	}
				// }
			}
		}
		.main-nav-items {
			ul {
				li {
					a.depth-1 {
						@include media ($maxWidthAndHeight) {
							display: flex;
							align-items: center;
							&:before {
								content: " ";
								display: inline-block;
								width: 25px;
								height: 25px;
								margin-right: 5px;
								background: url('../img/icon_file_document.svg') no-repeat center;
								background-size: contain;
							}
						}
					}
				}
			}
		}

	}
	@include media($laptopHeightOnly) {
		padding: 40px;
		nav {
			ul {
				li {
					a.depth-1 {
						padding: 22px 0;
					}
				}
			}
		}
	}
	@include media($smDeskAndLess) {
		width: $mainNavWidth*0.75;
		padding: 47px 30px;
		nav {
			ul {
				li {
					a.depth-1 {
						padding: 20px 0;
						font-size: 15px;
						white-space: normal;
					}
					&:not(.active) {
						a.depth-1 {
							&:hover {
								padding-left: 5px;
							}
						}
					}
				}
			}
		}
	}
	@include media($maxWidthAndHeight) {
		z-index: 999;
		height: $tabNavHeight;
		width: 100vw;
		padding: 0 30px;
		display: flex;
		justify-content: flex-start;
		flex-flow: column wrap;
		background: #fff;
		border-bottom: 1px solid $brand-grey1;
		.header-top {
			width: 100%;
			height: $tabNavHeight;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		.logo {
			position: relative;
			z-index: 1;
			margin-right: auto;
			height: 42px;
			img {
				max-height: 100%;
				width: auto;
			}
		}
		nav {
			position: absolute;
			z-index: -1;
			top: -1px;
			left: 0;
			width: 100%;
			padding: 15px 30px;
			opacity: 0;
			transform: scale(1,0);
			transform-origin: top center;
			transition: all 0.2s ease;
    	overflow: auto;
    	-webkit-overflow-scrolling: touch;
			ul {
				li {
					a {
						padding: 16px 0;
						&:hover, &:active {
							//border-bottom: 1px solid $brand-grey2;
						}
					}
					&.active {
						a {
							//border-bottom: 1px solid $brand-grey2;
						}
					}
					&.first {
						a { border-top: 0; }
					}
					&.last {
						a { border-bottom: 0; }
					}
				}
				ul {
  				position: relative;
  				top: 0;
  				left: 0;
  				li {
    				a {
      				background: none;
    				}
  				}
				}
			}
			&.open {
				height: 100%;
				opacity: 1;
				transform: scale(1);
			}
		}
		.nav-burger {
			display: block;
		}
	}
	@include media($mobileOnly) {
		height: $mobileNavHeight;
		.header-top {
			height: $mobileNavHeight;
		}
		nav {
			margin-top: $mobileNavHeight;
			padding: 0 30px;
			ul {
				li {
					a.depth-1 {
						padding: 12px 0;
						font-size: 14px;
					}
				}
			}
		}
	}
}

.nav-burger, .search-nav {
	display: flex;
	flex-flow: column wrap;
	width: 45px;
	height: 39px;
	padding: 10px 8px;
	@include media($mobileOnly) {
		transform: scale(0.8);
	}
	span {
		display: block;
		width: 100%;
		height: 3px;
		background: $brand-primary1;
	}
	&:hover {
		cursor: pointer;
		color: $brand-secondary2;
		span {
			background: $brand-secondary2;
		}
	}
}

.nav-burger {
	transition: all 0.5s ease;
	display: none;
	position: relative;
	span {
		&.middle {
			margin: 5px 0;
		}
	}
	&.animate {
		span {
			&.top {
				animation-name: hamburgerTop;
				animation-duration: 0.5s;
				animation-timing-function: ease;
				animation-iteration: once;
				animation-fill-mode: both;
			}
			&.middle {
				animation-name: hamburgerMiddle;
				animation-duration: 0.5s;
				animation-timing-function: ease;
				animation-fill-mode: both;
			}
			&.bottom {
				animation-name: hamburgerBottom;
				animation-duration: 0.5s;
				animation-timing-function: ease;
				animation-fill-mode: both;
			}
		}
	}
	&.revert {
		span {
			animation-direction: reverse;
		}
	}
	&.active {
		span {
			&.top {
				transform: translateY(8px) rotate(45deg);
			}
			&.middle {
				opacity: 0;
			}
			&.bottom {
				transform: translateY(-8px) rotate(-45deg);
			}
		}
	}
	&:before {
		position: absolute;
		top: 11px;
		right: 110%;
		content: "Menu";
		text-transform: uppercase;
		font-weight: $font-weight-medium;
		letter-spacing: 1px;
		@media(max-width:376px) {
			display: none;
		}
	}
}

.search-nav {
	display: none;
	outline: 0;
	position: relative;
	@include media ($maxWidthAndHeight) {
		display: flex;
		align-items: flex-end;
		margin-left: 25px;
		border: 0;
		background: transparent;
		font-size: 25px;
		color: $brand-primary1;
		transition: opacity 0.25s linear, transform 0.5s ease;
		&:hover {
			color: $brand-secondary2;
		}
	}
	@include media ($ptabAndLess) {
		margin-left: 10px;
	}
	span {
		position: absolute;
		bottom: 8px;
		left: 8px;
		width: 29px;
		opacity: 0;
	}
	.fa {
		opacity: 1;
		margin-top: -3px;
		display: block;
		width: 100%;
		transition: all 0.5s ease;
	}
	&.animate {
		.fa {
			animation-name: squishy;
			animation-duration: 0.5s;
			animation-timing-function: ease;
			animation-fill-mode: both;
		}
		.top {
			animation-name: squishyXLeft;
			animation-duration: 0.5s;
			animation-timing-function: ease;
			animation-fill-mode: both;
		}
		.bottom {
			animation-name: squishyXRight;
			animation-duration: 0.5s;
			animation-timing-function: ease;
			animation-fill-mode: both;
		}
	}
	&.revert {
		span {
			animation-direction: reverse;
		}
	}
	&.active {
		.fa {
			opacity: 0;
			transform: scale(1,0.15);
		}
		span {
			opacity: 1;
			&.top {
				transform: translateY(-10px) rotate(45deg);
			}
			&.bottom {
				transform: translateY(-10px) rotate(-45deg);
			}
		}
	}
}

.mobile-header {
	display: none;
	padding: 20px 0 16px 0;
	font-size: 13px;
	color: $brand-secondary2;
	font-weight: $font-weight-medium;
	text-transform: uppercase;
	letter-spacing: 1px;
	@include media($maxWidthAndHeight) {
		display: block;
	}
}



.intro {
	margin-bottom: 60px;
	padding: 47px 0;
	background-image: url('../img/jelly_archive.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: bottom center;
	color: #fff;
	font-size: 18px;
	line-height: 1.55;
	transition: margin 0.3s ease;
	&.extra-margin {
		margin-bottom: 57px !important;
	}
	&.home {
		//min-height: 281px;
    // transition: all 1s ease-in-out;
		background-image: none;//url('../img/jelly_homepage.png');
		@include media($smDeskAndLess) {
			min-height: 0;
		}
		@include media($laptopHeightOnly) {
			min-height: 0;
		}
		@include media($mobileOnly) {
			background-image: url('../img/mobile/jelly_homepage.png');
		}
	}
	&.articles, &.archive {
		min-height: 150px;
		@include media ($mobileOnly) {
			min-height: 75px;
		}
	}
	&.articles {
		background-image: url('../img/jelly_articles.png');
	}
	&.single {
		//margin-bottom: 0;
		@include media ($maxWidthAndHeight) {
			padding: 20px 0;
		}
	}
	&.legacy-magazine {
		background-image: url('../img/jelly_magazine.png');
	}
	&.archive {
		margin-bottom: 30px;
	}
	&.interior {
		position: relative;
		background-image: url('../img/jelly_interior_extra.png');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 100% 57%;
		margin-bottom: 50px;
		padding-top: 530px;
		overflow: hidden;
		body.page-node-10 & { // Initiating Change
			background-image: url('../img/jelly_interior1.png');
		}
		body.page-node-11 & { // Strengthening Communities
			background-image: url('../img/jelly_interior2.png');
		}
		body.page-node-13 & { // Guidelines and Clinical Support
			background-image: url('../img/jelly_interior3.png');
		}
		body.page-node-14 & { // Collaborative Practice
			background-image: url('../img/jelly_interior4.png');
		}
		@include media ($xlwideDesk) {
			padding-top: 750px;
			background-position: center bottom -150px;
		}
		@include media ($smDeskAndLess) {
			padding-top: 500px;
			background-size: 100% 50%;
		}
		@include media ($maxWidthAndHeight) {
			height: 100%;
			display: flex;
			align-items: flex-end;
			padding-top: 0;
			padding-bottom: 15px;
			background-size: 100% 65%;
			background-position: center bottom -50px;
			font-size: 16px;
		}
		@include media ($mobileOnly) {
			margin-bottom: 30px;
			line-height: 1.25;
			background-position: center bottom;
			background-size: 100% 50%;
			body.page-node-10 & { // Initiating Change
				background-image: url('../img/mobile/jelly_interior1.png');
			}
			body.page-node-11 & { // Strengthening Communities
				background-image: url('../img/mobile/jelly_interior2.png');
			}
			body.page-node-13 & { // Guidelines and Clinical Support
				background-image: url('../img/mobile/jelly_interior3.png');
			}
			body.page-node-14 & { // Collaborative Practice
				background-image: url('../img/mobile/jelly_interior4.png');
			}
		}
		h1 {
			margin-bottom: 15px;
			font-size: 34px;
			@include media($smDeskAndLess) {
				font-size: 28px;
			}
			@include media ($maxWidthAndHeight) {
				font-size: 24px;
				margin-bottom: 0;
			}
			@include media ($mobileOnly) {
				font-size: 20px;
			}
		}
		p {
			@include media ($mobileOnly) {
				font-size: 15px;
			}
		}
		.banner-img {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: top center;
			@include media ($maxWidthAndHeight) {
				background-size: auto 100%;
			}
		}
		.scroll-hint {
			display: none;
			text-align: right;
			@include media ($maxWidthAndHeight) {
				display: block;
			}
		}
	}
}

.header-icon {
	margin: 0 auto;
	width: 115px;
	height: 115px;
	padding: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: $brand-primary1;
	svg {
		display: block;
		fill: #fff;
		width: 50px;
		height: 50px;
	}
}

// Global Styles -- Footer //
// -----------------------------------

body.front footer .container {
	padding-right: 50px;
}

footer {
	#autocomplete {
		padding: 5px 0;
		background: #fff;
		border: 1px solid $brand-grey2;
		border-top: 0;
		top: calc(100% + 1px) !important;
		left: -1px !important;
		width: calc(100% + 2px) !important;
		@include media ($maxWidthAndHeight) {
			font-size: 16px;
		}
		img {
			width: 15px;
			margin-right: 15px;
		}
		li {
			padding: 0 10px;
			background: transparent;
			&.selected {
				background: $brand-grey1;
			}
			a {
				display: block;
				padding: 10px;
				color: $body-text;
			}
		}
	}
}

footer, .home-search {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 50px 0 30px 0;
	background: $brand-primary1;
	color: #fff;
	.front & {
		margin-bottom: -17em;
		align-self: flex-end;
		@include media ($maxWidthAndHeight) {
			padding: 0;
			.columns, .footer-credits {
				display: none;
			}
		}
	}
	.title {
		display: none;
	}
	.columns {
		display: flex;
		justify-content: space-between;
		@include media ($maxWidthAndHeight) {
			display: none;
			margin-left: -20px;
			margin-right: -20px;
		}
		@include media ($ptabAndLess) {
			flex-wrap: wrap;
		}
		@include media($mobileOnly) {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.column {
		padding: 0 20px;
		width: 33%;
		@include media ($ptabAndLess) {
			padding: 0;
			// width: 48%;
			margin-bottom: 20px;
		}
		@include media ($mobileOnly) {
			width: 100%;
		}
		.footer-column-title {
			margin-bottom: 3px;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 1.69;
			font-weight: $font-weight-medium;
			letter-spacing: 1px;
		}
		ul:not(.sharing) {
			padding: 0;
			margin: 0;
			li {
				list-style: none;
				a, span {
					display: inline-block;
					padding: 3px 0;
					font-size: 14px;
					font-weight: $font-weight-light;
					color: #fff;
					&:hover, &:active, &:focus {
						cursor: pointer;
						color: $brand-secondary3;
					}
				}
			}
		}
		&.utility-links {
			ul:not(.sharing) {
				li {
					a[href^="mailto"] {
						display: none;
					}
				}
			}
		}
		.sharing {
			li {
				a {
					background: transparent;
					border-color: #fff;
					svg {
						g, path {
							fill: #fff;
						}
					}
					&:hover {
						border-color: $brand-secondary3;
						svg {
							g, path {
								fill: $brand-secondary3;
							}
						}
					}
				}
			}
		}
	}

	#cymhsu-search-form {
		width: 100%;
		margin-bottom: 50px;
		@include media($maxWidthAndHeight) {
			position: fixed;
			top: -100%;
			right: 0;
			width: 100%;
			height: 100%;
			padding: 30px;
			background: #fff;
			transition: all 0.3s ease;
			&.open {
				top: $tabNavHeight;
			}
			.mobile-header {
				font-size: 16px;
			}
		}
		@include media($mobileOnly) {
			padding-top: 0;
			&.open {
				top: $mobileNavHeight;
			}
		}
		.form-item, .form-action {
			margin: 0;
			flex-grow: 1;
		}
		button, input {
			outline: 0;
			border: 0;
			background: transparent;
			color: #fff;
			@include media($maxWidthAndHeight) {
				color: $brand-primary1;
			}
		}
		button {
			font-size: 25px;
		}
		input {
			padding: 16px;
			font-size: 22px;
			letter-spacing: 1px;
			@include media($mobileOnly) {
				font-size: 18px;
			}
		}
		span {
			@include media($maxWidthAndHeight) {
				display: block;
				width: 100%;
			}
		}
		// #edit-search-autocomplete-aria-live {
		// 	display: none;
		// }
		.form-wrapper {
			display: flex;
			padding: 0 16px;
			border: 1px solid $brand-grey2;
			position: relative;
			.form-type-searchfield {
				display: flex;
				justify-content: center;
				align-items: center;
				&:before {
					content: "\f002";
					font-family: "FontAwesome";
					font-size: 20px;
					@include media($maxWidthAndHeight) {
						margin-right: 5px;
						color: $brand-primary1;
					}
				}
			}
			.form-submit {
  			margin-top: 0;
				width: 4%;
				background: url('../img/search-arrow.png') no-repeat center;
				@include media($maxWidthAndHeight) {
					width: 12%;
					opacity: 0;
					background: url('../img/search-arrow-dark.png') no-repeat right center;
					transform: translateX(-100%);
					transition: all 0.25s ease;
					&.in {
						opacity: 1;
						transform: none;
					}
				}
				@include media($ptabAndLess) {
					padding: 0;
				}
				@include media($mobileOnly) {
					width: 25%;
				}
			}
			#edit-search {
				width: 94%;
				flex-grow: 1;
			}
			@include media($maxWidthAndHeight) {
				margin-bottom: 40px;
				border: 1px solid $brand-primary1;
				#edit-search {
					padding-left: 10px;
				}
			}
			@include media($mobileOnly) {
				margin-bottom: 0;
				::-webkit-input-placeholder {
					color: transparent;
				}
				::-moz-placeholder {
					color: transparent;
				}
				:-ms-input-placeholder {
					color: transparent;
				}
				:-moz-placeholder {
					color: transparent;
				}
			}
		}
		#edit-columns {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 30px 0;
			border-bottom: 1px solid $brand-grey2;
			span {
				display: none;
			}
			> .form-item {
				width: 31.5%;
				padding: 0 20px;
				> label {
					display: none;
				}
			}
			label {
				position: relative;
				display: block;
				padding-left: 28px;
				margin-bottom: 12px;
				&:hover {
					cursor: pointer;
				}
				&:before, &:after {
					position: absolute;
					top: 0;
					width: 16px;
					height: 16px;
				}
				&:before {
					content: " ";
					left: 0;
					border: 1px solid rgba($brand-grey2,0.5);
					@include media($maxWidthAndHeight) {
						border: 1px solid $brand-grey2;
					}
				}
				&:after {
					content: "\f00c";
					left: 1px;
					font-family: "FontAwesome";
					line-height: 16px;
					vertical-align: middle;
					opacity: 0;
					transform: rotate(70deg);
					transition: all 0.2s ease;
				}
				&.checked {
					&:after {
						opacity: 1;
						transform: rotate(0deg);
					}
				}
			}
			input[type="checkbox"] {
				opacity: 0;
				display: none;
			}
			@include media($maxWidthAndHeight) {
				flex-flow: column nowrap;
				max-height: 600px;
				overflow: scroll;
				color: $body-text;
				border-bottom: 0;
				span {
					display: block;
					margin-bottom: 25px;
					font-size: 20px;
					font-weight: $font-weight-light;
				}
				> .form-item {
					padding: 0;
					width: 100%;
					> label {
						display: block;
						width: 100%;
						padding: 13px 25px 9px 25px;
						color: $brand-primary1;
						font-size: 16px;
						font-weight: $font-weight-medium;
						text-transform: uppercase;
						letter-spacing: 2px;
						border: 1px solid $brand-primary1;
						&:before {
							display: none;
							content: "";
						}
						&:after {
							content: "\f107";
							font-size: 36px;
							font-weight: $font-weight-light;
							opacity: 1;
							transform: none;
							left: auto;
							right: 25px;
							top: 16px;
							width: auto;
							height: auto;
							transform-origin: center;
						}
						&:hover {
							color: $brand-secondary2;
						}
						&.active {
							&:after {
								transform: rotate(180deg);
							}
						}
					}
					> .form-checkboxes {
						display: none;
						padding: 10px 25px;
					}
				}
			}
			@include media($mobileOnly) {
				max-height: 300px;
				span {
					margin-bottom: 15px;
					font-size: 16px;
				}
				> .form-item {
					label {
						font-size: 17px;
					}
				}
			}
		}
	}

	.footer-credits {
		margin-top: 30px;
		display: flex;
		align-items: flex-end;
		font-size: 12px;
		@include media ($ptabAndLess) {
			flex-wrap: wrap;
		}
		img {
			margin-right: 50px;
			max-height: 75px;
			@include media ($ptabAndLess) {
				margin-right: 5%;
				width: 28%;
				max-height: none;
				height: auto;
			}
			@include media ($mobileOnly) {
				width: 60%;
			}
		}
		p {
			margin: 0 0 8px 0;
			@include media ($mobileOnly) {
				width: 100%;
				margin: 0;
			}
		}
	}
}

.fb-share-button {
	display: inline-block;
}

.sharing {
	margin: 0;
	padding: 0;
	li {
		list-style: none;
		&#searchbtn {
			@include media($maxWidthAndHeight) {
				display: none;
			}
		}
		a {
			width: 60px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: column nowrap;
			color: $brand-secondary2;
			background: #fff;
			text-transform: uppercase;
			font-size: 20px;
			@include media($maxWidthAndHeight) {
				width: 50px;
				height: 50px;
			}
			svg {
				@include media($maxWidthAndHeight) {
					width: 23px;
					height: 23px;
				}
				g, path {
					fill: $brand-secondary2;
				}
			}
			&.back-to-top {
				font-size: 12px;
				@include media($maxWidthAndHeight) {
					font-size: 10px;
				}
				.fa {
					font-size: 15px;
				}
			}
			&:hover, &:active {
				background: $brand-secondary2;
				color: #fff;
				svg {
					g, path {
						fill: #fff;
					}
				}
			}
		}
	}
	&.on-page {
		display: flex;
		justify-content: flex-start;
		li {
			margin-right: 5px;
			a {
				width: 30px;
				height: 30px;
				border: 1px solid $brand-secondary2;
				border-radius: 50%;
				svg {
					width: 17px;
					height: 17px;
				}
			}
		}
	}
	&.article-share {
		margin-top: 50px;
		align-items: center;
		li:first-child {
			margin-right: 10px;
			font-weight: $font-weight-medium;
		}
	}
}

#side-sticky {
	z-index: 99;
	position: fixed;
	right: -62px;
	bottom: 30%;
	border-left: 1px solid $brand-secondary2;
	transition: right 0.3s ease;
	@include media ($mobileOnly) {
		right: -100%;
		bottom: 0;
		display: flex;
		justify-content: flex-end;
		border: 0;
	}
	li {
		display: block;
		a {
			border-bottom: 1px solid $brand-secondary2;
		}
		&:first-child {
			a {
				border-top: 1px solid $brand-secondary2;
			}
		}
		&#social-share {
			display: none;
		}
		@include media ($mobileOnly) {
			a {
				border: 1px solid $brand-secondary2;
				border-right: 0;
				background: rgba(255,255,255,0.9);
				&:hover {
					background: rgba($brand-secondary2,1);
				}
			}
			&#social-share {
				display: block;
				z-index: 10;
			}
			&.social-channel {
				transition: all 0.3s ease;
				position: relative;
				z-index: 8;
				opacity: 0;
				&:first-child {
					transform: translateX(150px);
				}
				&:nth-child(2) {
					transform: translateX(100px);
				}
				&:nth-child(3) {
					transform: translateX(50px);
				}
				&.in {
					transform: none;
					opacity: 1;
					&:first-child {
						transition: all 0.5s ease;
					}
					&:nth-child(2) {
						transition: all 0.35s ease;
					}
					&:nth-child(3) {
						transition: all 0.2s ease;
					}
				}
			}
		}
	}
	&.show {
		right: 0;
	}
}

// Homepage Styles //
// -----------------------------------

.front main, .home-features {
	@include media($maxWidthAndHeight) {
		height: 100%;
	}
}

#homepage-video {
	display: none;
	position: fixed;
	margin-left: -$mainNavWidth;
	width: 100%;
	height: 100%;
	background: rgba($brand-primary1,0.9);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column nowrap;
	@include media($smDeskAndLess) {
		margin-left: -$mainNavWidth*0.75;
	}
	@include media($maxWidthAndHeight) {
		margin-left: 0;
		margin-top: -$tabNavHeight;
	}
	.close {
		width: 60%;
		margin-bottom: 10px;
		display: block;
		text-align: right;
		color: #fff;
		font-size: 15px;
		font-weight: $font-weight-medium;
		text-transform: uppercase;
		letter-spacing: 1px;
		transition: all 0.25s ease;
		@include media($maxWidthAndHeight) {
			width: 80%;
		}
		@include media($mobileOnly) {
			width: 95%;
		}
		&:hover {
			cursor: pointer;
			color: $brand-secondary3;
		}
	}
	iframe {
		width: 60%;
		height: 60%;
		@include media($maxWidthAndHeight) {
			width: 80%;
		}
		@include media($mobileOnly) {
			width: 95%;
		}
	}
}

.home-features {
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	background: $brand-secondary2;
	color: #fff;
	@include media($maxWidthAndHeight) {
		flex-direction: column;
		position: relative;
	}
	.more-info {
		color: #fff;
		&:after {
			background: #fff;
		}
	}
	&.has--events .slider-box {
		width: 70%;
		@include media($maxWidthAndHeight) {
			width: 100%;
		}
	}
	.slider-box {
		width: 70%;
		position: relative;
		font-size: 0;
		@include media($maxWidthAndHeight) {
			width: 100%;
			height: 100%;
		}
		.intro {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			margin: 0;
			display: flex;
			align-items: flex-end;
			padding-top: 125px;
			padding-bottom: 0;
			&, & * {
				font-weight: $font-weight-medium;
				white-space: normal;
			}
			p {
				line-height: 1.25;
			}
			@media(max-height:900px) and (min-width:1269px) {
				//padding-bottom: 10px;
				background-position: center bottom;
			}
			@include media($laptopHeightOnly) {
				padding-top: 44px;
				//padding-bottom: 15px;
				p {
					font-size: 16px;
				}
			}
			@include media($maxWidthAndHeight) {
				font-size: 16px;
			}
			@include media($mobileOnly) {
				padding-top: 70px;
			}
		}
		.slideshow,	.slide, .slider-img {
			width: 100%;
			height: 100%;
		}
		.slideshow {
			overflow: hidden;
			position: absolute;
			top: 0;
			white-space: nowrap;
			.container {
  			padding: 0;
			}
		}
		/*.slide {
  		position: relative;
  		&:before {
    		content:'';
    		display: block;
    		position: absolute;
    		bottom: 0;
    		width: 100%;
    		height: 100%;
    		background: rgba(255,255,0,.5);
    		background: url('../img/jelly_homepage.png') center bottom no-repeat;
    		background-size: 100% 100%;
  		}
		}*/
		.slider-img {
			display: inline-block;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			font-size: 0;
			@include media($laptopHeightOnly) {
				//background-position: center bottom;
			}
			/*
			&.slide-1 {
				@include media ($mobileOnly) {
					background-size: auto 115%;
					background-position: right -80px bottom;
				}
			}
			&.slide-3 {
				@include media($laptopHeightOnly) {
					background-position: center;
				}
				@include media($mobileOnly) {
  				background-color: #fff;
  				background-size: 100% auto;
  				background-position: center top;
				}
			}*/
		}
		.slide-text {
			display: none;
    		background: url('../img/jelly_homepage.png') center bottom no-repeat;
    		background-size: 100% 100%;
    		padding: 80px 108px 30px;
				@include media($mobileOnly) {
  				padding: 20px;
				}
    		width: 100%;
			&.active {
				display: block;
			}
			figcaption {
  			font-size: 80%;
  			font-style: italic;
  			color: #fff;
  			text-align: right;
  			display: block;
  			margin: 0 0 40px;
			}
			a {
  			color: #fff;
  			text-decoration: underline;
  			&.more-info {
    			text-decoration: none;
  			}
			}
			svg g {
				fill: #fff;
			}
			div.more {
  			margin: 1rem 0 0;
			}

		}
		.slider-img, .slider-text {
			transition: all 0.3s ease-out;
		}
		.slides-pager {
			margin-left: auto;
			position: absolute;
			bottom: 20px;
			right: 20px;
			@include media ($mobileOnly) {
				display: none;
			}
			.indicator {
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				border: 1px solid #fff;
				background: transparent;
				&.active {
					background: #fff;
				}
				&:not(.active):hover {
					cursor: pointer;
					background: rgba(255,255,255,0.5);
				}
			}
		}
		.slider-links {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include media ($maxWidthAndHeight) {
				margin-top: 10px;
			}
			.slider-link {
				display: none;
				&.active {
					display: block;
				}
				img, svg {
					margin-right: 10px;
					@include media ($mobileOnly) {
						height: 20px;
					}
				}
				svg g {
					fill: #fff;
				}
			}
		}
	}
}

.calendar-indicator {
	display: none;
	@include media($maxWidthAndHeight) {
		width: 100%;
		height: $tabNavHeight;
		display: flex;
		justify-content: center;
		align-items: center;
		background: $brand-secondary2;
		border-bottom: 1px solid rgba(255,255,255,0.4);
	}
	@include media($mobileOnly) {
		height: $mobileNavHeight - 20;
		font-size: 12px;
		letter-spacing: 2px;
		opacity: 0.9;
	}
	.title-icon {
		display: inline-block;
		margin-right: 10px;
		fill: #fff;
	}
	span {
		margin-right: 20px;
		text-transform: uppercase;
		font-weight: $font-weight-medium;
	}
}

.mobile-home-links {
	display: none;
	// @include media($maxWidthAndHeight) {
	// 	display: block;
	// 	ul {
	// 		height: 100%;
	// 		margin: 0;
	// 		padding: 0;
	// 		display: flex;
	// 		flex-flow: column nowrap;
	// 		justify-content: space-around;
	// 		background: $brand-secondary2;
	// 		li {
	// 			list-style: none;
	// 			display: flex;
	// 			flex-grow: 1;
	// 			justify-content: flex-start;
	// 			align-items: center;
	// 			a {
	// 				display: block;
	// 				font-size: 20px;
	// 				padding: 0 $container-padding;
	// 				color: $brand-secondary3;
	// 			}
	// 		}
	// 	}
	// }
}


.calendar {
	width: 30%;
	padding: 40px 50px 40px 30px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	@media(max-height:755px) and (min-width: 1220px) {
		padding-top: 75px;
	}
	@include media($smDeskAndLess) {
		padding: 30px;
	}
	@include media($maxWidthAndHeight) {
		display: none;
		// position: absolute;
		// width: 100%;
		// top: 100%;
		// left: 0;
		// height: $mobileCalHeight;
		// justify-content: space-between;
		// background: $brand-secondary2;
	}
	// @include media($laptopHeightOnly) {
	// 	display: none;
	// }
	@include media($ptabAndLess) {
		padding: 50px 16px 0;
	}
	@include media($mobileOnly) {
		padding: 40px 30px;
	}
	.calendar-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.title-icon {
			display: inline-block;
			margin-right: 10px;
			fill: #fff;
		}
		.title-text {
			display: inline-block;
			font-size: 13px;
			letter-spacing: 2px;
		}
	}
	.calendar-block {
		padding: 29px 0;
		border-bottom: 1px solid rgba($brand-grey2, 0.6);
		@include media($smDeskAndLess) {
			padding: 18px 0;
		}
		@include media($mobileOnly) {
			padding: 10px 0;
		}
		/*
		&:last-child {
			@media(max-height:977px) and (max-width: 1220px) {
				display: none;
			}
		}
		&:nth-last-child(2) {
			@media(max-height:900px) and (max-width: 1220px) {
				display: none;
			}
		}
		&:nth-last-child(3) {
			@media(max-height:755px) and (max-width: 1220px) {
				display: none;
			}
		}
		&:nth-last-child(3) {
			@media(max-height:1080px) and (max-width: 1220px) {
				display: none;
			}
		}*/
		.calendar-block-date {
			text-transform: uppercase;
			font-size: 12px;
			line-height: 14px;
			font-weight: $font-weight-bold;
			letter-spacing: 2px;
		}
		.calendar-block-title {
			display: inline-block;
			margin: 10px 0;
			font-size: 16px;
			line-height: 1.62;
			font-weight: $font-weight-medium;
			color: inherit;
			&:hover {
				color: #fff;
			}
		}
		.calendar-block-desc, & * {
			font-size: 14px;
			line-height: 1.57;
		}
		.calendar-block-desc p {
			margin: 0;
		}
	}
}

// Articles Page //
// -----------------------------------

.bio {
	margin-bottom: 80px;
	padding-bottom: 70px;
	border-bottom: 1px solid rgba($brand-grey2,0.5);
	@include media($ptabAndLess) {
		margin-bottom: 40px;
		padding-bottom: 35px;
	}
	img {
		max-width: 100%;
		width: 115px;
		height: 115px;
		display: block;
		margin: 0 auto;
		border-radius: 50%;
	}
	h1 {
		margin: 20px 0 38px 0;
	}
	p {
		margin: 0;
		color: $brand-primary1;
		font-size: 16px;
		line-height: 1.63;
	}
}

.load-more {
	margin: 60px 0 50px 0;
}

// Interior Styles //
// -----------------------------------

.subnav {
	top: 0;
	.subnav-page-title {
		display: none;
	}
	.subnav-nav {
		position: relative;
		.current-section {
			display: none;
			font-size: 16px;
			line-height: 32px;
			text-align: right;
			overflow: hidden;
			@include media($smDeskAndLess) {
				text-align: left;
			}
			@include media ($maxWidthAndHeight) {
				display: block;
			}
			@include media ($mobileOnly) {
				font-size: 14px;
			}
		}
		ul {
			margin: 0;
			padding: 0;
			li {
				margin-right: 20px;
				margin-bottom: 10px;
				position: relative;
				list-style: none;
				display: inline-block;
				@include media($maxWidthAndHeight) {
					display: block;
					margin-bottom: 5px;
				}
				a {
					display: flex;
					align-items: center;
					margin-bottom: 5px;
					margin-top: 8px;
					line-height: 1.1em;
					font-size: 16px;
					font-weight: $font-weight-light;
					// color: $body-text;
					color: $brand-secondary3;
					transition: none;
					@include media ($mobileOnly) {
						font-size: 14px;
					}
				}
				span {
					margin-top: 5px;
					margin-left: 4px;
					// border-bottom: 1px solid transparent;
					border-bottom: 1px solid rgba($brand-secondary3,0.6);
					transition: all 0.2s ease;
					@include media ($maxWidthAndHeight) {
						border-bottom: 0;
					}
				}
				svg {
					fill: $brand-primary1;
					margin-right: 5px;
					height: 25px;
					width: 25px;
					@include media ($maxWidthAndHeight) {
						display: none;
					}
				}
				&.active, &:hover {
					cursor: pointer;
					a {
						color: $brand-secondary2;
					}
					span {
						border-bottom: 1px solid rgba($brand-secondary2,0.6);
						@include media ($maxWidthAndHeight) {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}
	&:not(.stick) {
		@include media ($maxWidthAndHeight) {
			position: relative;
			.container {
				&:before {
					display: inline-block;
					content: "Jump to section:";
					margin-bottom: 5px;
				}
			}
			.current-section {
				height: 45px;
				padding: 0 15px;
				line-height: 45px;
				vertical-align: middle;
				border: 1px solid rgba($brand-primary1,0.4);
				&:before, &:after {
					content: " ";
					position: absolute;
					top: 23px;
					display: block;
					width: 12px;
					height: 2px;
					background: $brand-primary1;
					border-radius: 2px;
					transition: all 0.2s linear;
				}
				&:before {
					right: 21px;
					transform: rotate(45deg);
				}
				&:after {
					right: 13px;
					transform: rotate(-45deg);
				}
			}
			ul {
				position: absolute;
				top: 45px;
				left: 0;
				width: 100%;
				transform: scale(1,0);
				transform-origin: top center;
				transition: all 0.2s ease;
				background: #fff;
				border: 1px solid $brand-grey2;
				border-top: 0;
				&.open {
					transform: none;
				}
				li {
					margin: 0;
					padding: 5px 15px;
					&:hover, &:active {
						background: $brand-grey1;
					}
				}
			}
			&.open {
				.current-section {
					&:before {
						transform: rotate(-45deg);
					}
					&:after {
						transform: rotate(45deg);
					}
				}
			}
		}
	}
	&.stick {
		transition: padding-top 0.5s linear;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		padding-left: $mainNavWidth;
		padding-top: 5px;
		width: 100%;
		background: $brand-secondary2;
		color: #fff;
		&.animate {
			animation: stickyNavDesktop 1s ease forwards;
		}
		@include media($smDeskAndLess) {
			padding-top: 15px;
			padding-left: $mainNavWidth*0.75;
		}
		@include media($maxWidthAndHeight) {
			padding-top: 0;
			padding-left: 0;
			top: $tabNavHeight;
			&.animate {
				animation: stickyNavTablet 1s ease forwards;
			}
		}
		@include media($mobileOnly) {
			top: $mobileNavHeight;
			&.animate {
				animation: stickyNavMobile 1s ease forwards;
			}
		}
		.container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			position: relative;
			@include media ($maxWidthAndHeight) {
				padding: 0;
			}
		}
		.subnav-page-title {
			width: 30%;
			display: inline-block;
			font-size: 20px;
			font-weight: $font-weight-bold;
			flex-grow: 1;
			@include media($smDeskAndLess) {
				width: 100%;
			}
			@include media($maxWidthAndHeight) {
				display: none;
			}
		}
		.subnav-nav {
			flex-grow: 1;
			padding: 20px 30px;
			transition: all 0.2s ease;
			@include media($smDeskAndLess) {
				padding: 10px 0;
			}
			// @include media($maxWidthAndHeight) {
			// 	padding: 10px 0;
			// }
			@include media($mobileOnly) {
				padding: 5px 0;
			}
			.current-section {
				display: block;
				position: relative;
				padding-right: 40px;
				color: #fff;
				@include media($maxWidthAndHeight) {
					padding: 0 30px;
					font-size: 14px;
				}
				&:before, &:after {
					content: " ";
					position: absolute;
					top: 14px;
					display: block;
					width: 12px;
					height: 2px;
					background: #fff;
					border-radius: 2px;
					transition: all 0.2s linear;
				}
				&:before {
					right: 8px;
					transform: rotate(45deg);
					@include media($maxWidthAndHeight) {
						right: 46px;
					}
				}
				&:after {
					right: 0;
					transform: rotate(-45deg);
					@include media($maxWidthAndHeight) {
						right: 38px;
					}
				}
			}
			&:hover {
				cursor: pointer;
			}
			&.open {
				min-width: 315px;
				background: lighten($brand-secondary2, 7%);
				@include media($smDeskAndLess) {
					background: transparent;
				}
				// @include media($maxWidthAndHeight) {
				// 	background: transparent;
				// }
				svg {
					display: none;
				}
				.current-section {
					&:before {
						transform: rotate(-45deg);
					}
					&:after {
						transform: rotate(45deg);
					}
				}
			}

			ul {
				margin: 0;
				padding: 20px 0;
				width: 100%;
				position: absolute;
				top: 100%;
				left: 0;
				background: lighten($brand-secondary2, 7%);
				transform: scale(1,0);
				transform-origin: top center;
				transition: all 0.2s ease;
				@include media($smDeskAndLess) {
					padding: 0;
				}
				@include media($maxWidthAndHeight) {
					padding: 10px 0;
				}
				&.open {
					transform: none;
				}
				li {
					display: block;
					margin-right: 0;
					transition: all 0.2s ease;
					a {
						padding: 10px 30px;
						color: #fff;
						font-size: 14px;
						font-weight: normal;
						span {
							border-bottom: 0;
						}
					}
					&:before {
						content: "";
						display: none;
					}
					&.active, &:hover {
						background: $brand-secondary2;
						a {
							span {
								border-bottom: 0;
							}
						}
					}
				}
			}
		}
		.misc-nav, .misc-menu {
			display: flex;
		}
	}
}

.subsection {
	padding: 100px 0 60px 0;
	@include media($smDeskAndLess) {
		padding: 60px 0 35px 0;
	}
	@include media($mobileOnly) {
		padding: 40px 0 30px 0;
	}
	&:nth-child(even) {
		background: $alternate-bg-color;
	}
	&:last-child {
		padding-bottom: 90px;
	}
	.field-type-text-long p {
		@include media($maxWidthAndHeight) {
			padding: 0 75px 0 0;
		}
		@include media($ptabAndLess) {
			padding: 0;
		}
	}
}

.subsection-title {
	font-size: 22px;
	line-height: 1.36;
	letter-spacing: 1px;
	font-weight: $font-weight-medium;
	color: $brand-secondary2;
	@include media ($mobileOnly) {
		font-size: 20px;
	}
	&:before {
		content: " ";
		display: block;
		margin-bottom: 11px;
		width: 75px;
		height: 3px;
		background: $brand-secondary2;
	}
}

.article-block {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding: 50px 0 60px 0;
	border-top: 1px solid lighten($brand-grey2,16%);
	border-bottom: 1px solid lighten($brand-grey2,16%);
	.article-content {
		width: 60%;
		.article-title {
			font-size: 22px;
			line-height: 1.27;
			letter-spacing: 1px;
			color: $brand-primary1;
			font-weight: $font-weight-medium;
		}
		.article-excerpt {
			margin: 20px 0 $interiorbox-padding 0;
			color: $interior-text;
			font-size: 17px;
			line-height: 1.53;
			font-weight: $font-weight-light;
		}
	}
	.article-thumb {
		width: 36%;
		img {
			display: block;
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
}

.author {
	display: flex;
	align-items: center;
	flex-grow: 1;
	width: 100%;
	margin-bottom: 36px;
	img {
		display: block;
		border-radius: 50%;
	}
	.author-info {
		margin-left: 13px;
		display: inline-block;
		font-size: 15px;
		line-height: 1.47;
		color: $brand-primary1;
		.author-name {
			position: relative;
			display: inline-block;
			border-bottom: 1px solid $brand-grey2;
			transition: all 0.25s ease;
		}
		.author-title {
			display: block;
			transition: all 0.25s ease;
		}
		&:hover {
			.author-name, .author-title {
				color: $brand-secondary3;
			}
		}
	}
}

.modular {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	@include media($smDeskAndLess) {
		justify-content: space-between;
		align-items: flex-start;
	}
	&.sep-title {
		margin: 60px 0 20px 0;
		padding: 0 $interiorbox-padding;
		font-size: 13px;
		line-height: 1.23;
		font-weight: $font-weight-medium;
		color: $brand-primary1;
		letter-spacing: 1px;
		text-transform: uppercase;
		@include media($smDeskAndLess) {
			padding: 0;
			margin: 30px 0 10px 0;
		}
	}
	&.article-teaser {
		padding: 30px;
		.icon {
			align-self: flex-start;
		}
		.title {
			@include media ($maxWidthAndHeight) {
				margin-bottom: 10px;
			}
		}
		.more-info {
			align-self: flex-end;
		}
	}
	.more-info {
		margin-left: auto;
		@include media($smDeskAndLess) {
			margin-left: 8%;
		}
		@include media($mobileOnly) {
			margin-left: 10%;
		}
	}
	.title {
		transition: color 0.5s ease;
		width: 65%;
		font-size: 16px;
		font-weight: $font-weight-light;
		@include media($smDeskAndLess) {
			width: 92%;
		}
		@include media($ptabAndLess) {
			margin-bottom: 5px;
		}
		@include media($mobileOnly) {
			width: 90%;
		}
	}
	.icon {
		margin-right: 18px;
		width: 25px;
		@include media($smDeskAndLess) {
			margin-right: 4%;
			width: 4%;
		}
		@include media($ptabAndLess) {
			margin-top: 3px;
		}
		@include media($mobileOnly) {
			width: 6%;
		}
	}
}
a.modular {
	.title {
		color: $interior-text;
	}
	&:hover {
		.title {
			color: $brand-secondary2;
		}
		.more-info {
			@extend .more-info-hover;
		}
	}
}

.downloads {
	.download {
		&:not(.featured) {
			padding: 22px $interiorbox-padding;
			border-bottom: 1px solid lighten($brand-grey2,20%);
			@include media($smDeskAndLess) {
				padding-left: 0;
				padding-right: 0;
			}
			.title {
				margin-top: 3px;
				@include media($smDeskAndLess) {
					margin-top: 0;
				}
			}
		}
		&.featured {
			.title {
				margin-left: 25px + 18px; // Match width of icon and margin of other download rows
			}
		}
	}
}

.featured {
	margin-top: 40px;
	margin-bottom: 45px;
	padding: 18px $interiorbox-padding 35px $interiorbox-padding;
	background: #fff;
	border: 1px solid lighten($brand-grey2,20%);
	border-radius: 5px;
	box-shadow: -2px 2px 1px 0 rgba(0,0,0,0.02);
	@include media($mobileOnly) {
		margin-left: -$interiorbox-padding;
		margin-right: -$interiorbox-padding;
	}
	.feature-label, .feature-thumb {
		display: block;
		width: 100%;
	}
	.feature-label {
		margin-bottom: 18px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		color: $brand-primary1;
		font-size: 15px;
		line-height: 1.47;
	}
	.feature-thumb, .field-type-image {
		margin-bottom: 15px;
		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
	.title {
		font-size: 21px;
		line-height: 1.24;
		font-weight: $font-weight-light;
		color: $interior-text;
		@include media($smDeskAndLess) {
			font-size: 18px;
		}
	}
	a.title {
		&:hover {
			color: $brand-secondary3;
		}
	}
	&.special { // This is for the "featured" boxes that has a bigger "featured" word than the file names
		margin-bottom: 20px;
		.feature-label {
			&, p {
				margin: 5px 0 10px 0;
				font-size: 16px;
				line-height: 1.24;
				font-weight: $font-weight-bold;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
		}
		.title {
			font-size: 16px;
			line-height: 1.47;
		}
	}
	&.magazine {
		.feature-thumb {
			margin-top: 10px;
		}
		.title {
			color: $brand-primary1;
			font-weight: $font-weight-medium;
		}
		.more-info {
			@include media($smDeskAndLess) {
				margin-left: 0;
			}
		}
	}
}

// Single Article Styles //
// -----------------------------------

.single-article {
	margin: 67px 0 90px 0;
	@include media ($maxWidthAndHeight) {
		margin: 40px 0 80px 0;
	}
	.author {
		img {
			height: 50px;
			width: 50px;
		}
		.author-info .author-name {
			border-bottom: 0;
		}
	}
	.article-featured-img {
		margin-bottom: 50px;
		max-width: 100%;
		width: 100%;
		height: auto;
	}
	h1 {
		margin-bottom: 36px;
		font-size: 22px;
		line-height: 1.36;
		color: $brand-primary1;
	}
	p {
		font-size: 16px;
		line-height: 1.57;
	}
	.article-nav {
		margin-bottom: 0;
		.more-info {
			text-transform: none;
			font-size: 14px;
			color: $brand-primary1;
			font-weight: $font-weight-light;
			letter-spacing: initial;
			&:after {
				background: $brand-secondary3;
			}
		}
	}
}

.article-nav {
	margin: 30px 0;
	display: flex;
	justify-content: space-between;
	position: relative;
	overflow: hidden;
	.article-nav-link {
		display: flex;
		flex-grow: 1;
		overflow: hidden;
		&:nth-child(even) {
			flex-direction: row-reverse;
			text-align: right;
		}
	}
	span {
		// position: relative;
		// top: -3px;
		overflow-x: hidden;
		width: 0;
		white-space: nowrap;
		&:after {
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			position: relative;
			background: $brand-secondary3;
		}
		&.next:after {
			right: -100%;
		}
		&.prev:after {
			left: -100%;
		}
	}
	.more-info {
		&:hover + span {
			width: auto;
			padding: 0 10px;
		}
		&.next {
			&:hover {
				& + span {
					// right: 0;
					animation: articleNavText 0.5s ease;
					animation-fill-mode: forwards;
					&:after {
						animation: barFromRight 0.6s ease 0.25s;
						animation-fill-mode: forwards;

					}
				}
			}
		}
		&.prev {
			&:hover {
				& + span {
					// left: 0;
					animation: articleNavText 0.5s ease;
					animation-fill-mode: forwards;
					&:after {
						animation: barFromLeft 0.6s ease 0.25s;
						animation-fill-mode: forwards;

					}
				}
			}
		}
	}
}

// Calendar/Event Archive Styles //
// -----------------------------------

.page-events {
	h1 {
		margin-top: 30px;
	}
}

.view-events {
	margin: 50px 0;
	@include media ($mobileOnly) {
		margin: 20px 0;
	}
	.view-content {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.views-row {
			width: 100%;
			.modular {
				margin-top: 0;
			}
		}
	}
}

.event-block {
	display: flex;
	align-items: flex-start;
	padding: 30px 0;
	border-bottom: 1px solid lighten($brand-grey2,20%);
	.calendar-badge {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 10px 20px 7px 20px;
		margin-right: 20px;
		border: 1px solid $brand-primary1;
		border-radius: 0 0 15px 15px;
		color: $brand-primary1;
		.month {
			text-transform: uppercase;
			font-size: 13px;
			letter-spacing: 1px;
		}
		.date {
			font-size: 30px;
			font-weight: $font-weight-medium;
		}
	}
	.event-title {
		margin-top: 5px;
		margin-bottom: 20px;
		display: block;
		font-size: 20px;
		color: $body-text;
	}
	a.event-title {
		&:hover, &:active {
			color: $brand-secondary3;
		}
	}
}

// Search //
// -----------------------------------

.search-results {
	margin-bottom: 90px;
	@include media ($maxWidthAndHeight) {
		margin-bottom: 50px;
	}
	.search-result {
		margin-bottom: 25px;
		padding: 20px;
		border: 1px solid lighten($brand-grey2,20%);
		box-shadow: -2px 2px 1px 0 rgba(0,0,0,0.02);
	}
	.source {
  	padding: 0 43px;
	}
}


// General forms:

input[type="text"],
input[type="email"],
input[type="password"] {
	margin: 5px 0;
	padding: 15px 20px;
	outline: 0;
	border: 1px solid $brand-grey2;
	@include media ($ptabAndLess) {
		width: 100%;
	}
	&:focus, &:active {
		border: 1px solid $brand-secondary2;
	}

}
// Use Log In //
// -----------------------------------

.page-user {
	h1 {
		margin-top: 50px;
		margin-bottom: 0;
		font-size: 36px;
	}
}

form#user-login {
	margin: 100px 0;
	@include media ($ptabAndLess) {
		margin: 50px 0 100px 0;
	}
	.form-item {
		margin-bottom: 35px;
		color: $brand-primary1;
		label {
			display: block;
			font-weight: $font-weight-bold;
			font-size: 15px;
			text-transform: uppercase;
			letter-spacing: 2px;
		}
		input {
		}
		.description {
			margin-bottom: 20px;
			color: lighten($brand-primary1,10%);
		}
	}
}
form {
	.form-submit {
		margin-top: 50px;
		padding: 7px 15px;
		border: 2px solid $brand-secondary2;
		background: transparent;
		color: $brand-secondary2;
		font-weight: $font-weight-medium;
		font-size: 18px;
		transition: background 0.25s ease;
		&:hover, &:active {
			background: $brand-secondary2;
			color: #fff;
		}
	}
}


a.permalink {
  font-size: 11px;
  display: inline-block;
  margin: 10px 30px 0;
  position: relative;
  bottom: -10px;
}

div.anchor {
  position: relative;
  top: -100px;
}
