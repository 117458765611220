@import "../neat/neat-helpers";
$max-width: 1300px;

// NEAT EXAMPLES

// div.container {
//   @include outer-container;
// }

// div.element {
//   @include span-columns(6);
// }


// Define your breakpoints
$ptab: new-breakpoint(601px);
$tab: new-breakpoint(769px);
$desk: new-breakpoint(1025px);
$wideDesk: new-breakpoint(1301px);
$xlwideDesk: new-breakpoint(1801px);
$mobileOnly: new-breakpoint(max-width 600px);
$ptabAndLess: new-breakpoint(max-width 768px);
$tabAndLess: new-breakpoint(max-width 1024px);
$smDeskAndLess: new-breakpoint(max-width 1220px);
$mdDeskAndLess: new-breakpoint(max-width 1485px);

$laptopHeightOnly: new-breakpoint(max-height 750px);

$maxWidthAndHeight: new-breakpoint(max-height 750px or max-width 1024px);
$minWidthAndHeight: new-breakpoint(min-height 751px or min-width 1025px);

// Bourbon Neat Grid
.container, .full-container {
  @include fill-parent();
  @include row(table);
}

.col-grid-1 {
  @include span-columns(1);
}

.col-grid-2 {
  @include span-columns(2);
  vertical-align:top;
}

.col-grid-3 {
  @include span-columns(3);
  vertical-align:top;
}

.col-grid-4 {
  @include span-columns(4);
  vertical-align:top;
}

.col-grid-5 {
  @include span-columns(5);
  vertical-align:top;
}

.col-grid-6 {
  @include span-columns(6);
  vertical-align:top;
}

.col-grid-7 {
  @include span-columns(7);
  vertical-align:top;
}

.col-grid-8 {
  @include span-columns(8);
  vertical-align:top;
}

.col-grid-9 {
  @include span-columns(9);
  vertical-align:top;
}

.col-grid-10 {
  @include span-columns(10);
  vertical-align:top;
}

.col-grid-11 {
  @include span-columns(11);
  vertical-align:top;
}

.col-grid-12 {
  @include span-columns(12);
  vertical-align:top;
}
