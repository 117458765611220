$brand-primary1: #53565A; //grey
$brand-primary2: #05C3DE; //teal
$brand-secondary1: #EA7F1B; //orange
$brand-secondary2: #565CBE; //purple
$brand-secondary3: #C65CF1; //magenta
$brand-grey1: #F5F5F5;
$brand-grey2: #B9BCBF;
$alternate-bg-color: #FAFAFA;
$interior-text: #000;

$logo-color: #53565A;
$body-text:#403c4c;
$teal: #7cccd4;
$light-grey: #ececec;

$cubBez: cubic-bezier(.6,.19,.52,.94);

$lighter-text: #bab9bf;

$darker-text:#414042;

$hover-overlay-bg: #414042;