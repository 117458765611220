#admin-menu {
  width:220px;
  height:100%;
  background:$body-text;
  position: fixed;
  left:0;
  top:0;
  bottom:0;
  color:#fff;
  font-size:14px;
  border-right:1px solid rgba(255,255,255,0.1);
  z-index:10000;

  .profile {
    padding:40px 30px;
    strong {
      position:relative;
      i {
        position:absolute;
        top:3px;
        right:-15px;
      }

    }
    img {
      border-radius:100px;
      width:50px;
      margin-bottom:5px;

    }
  }

  .shortcut-items {
    .shortcut-item {
      padding:15px 30px;
      &:hover {
        background:#393745;
      }
      i {
        width:25px;
      }
    }
  }
}

body.admin {}